export default   {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '420,450,528,498,565,511,701,549,704,540,731,551,727,563,748,567,764,555,779,584,802,577,818,576,845,574,830,616,812,619,799,624,787,628,769,634,742,638,514,572,392,517'
    }
  },
  name: 'Очередь 1',
  id: 1,
  imgForModal: {
    img: '/assets/area1.svg',
    viewBox: {
      x: '376',
      y: '443',
      width: '481',
      height: '204.5',
    },
  },
  active: true,
  showBorder: false,
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm433.1 467.7c0 .1-.1.2-.1.3-1.4 3.3-2.8 6.7-4.2 10-.3.6-.8.8-1.4.5-2.1-.9-4.3-1.8-6.4-2.7-.8-.3-.9-.7-.7-1.3 1.4-3.2 2.7-6.4 4.1-9.7.1-.2.2-.4.4-.5s.5-.3.6-.3c2.5 1 4.9 2.1 7.4 3.2.2 0 .2.3.3.5z',
      },
      type: 'expencive-comfort-wright-style',
      number: {
        n: 32,
        x: 426.5,
        y: 473.3,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm450.2 474.9c0 .2 0 .5-.1.7-1.4 3.2-2.7 6.4-4.1 9.6-.3.6-.8.9-1.4.6-2.1-.9-4.3-1.8-6.4-2.7-.8-.3-.9-.8-.6-1.7 1.3-3 2.5-6 3.8-9 .1-.2.2-.4.4-.6.2-.4.8-.7 1.2-.5 2.3 1 4.7 2 7 3.1 0 0 .1.4.2.5z',
      },
      type: 'expencive-comfort-wright-style',
      number: {
        n: 33,
        x: 443.5,
        y: 481.3,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm467.4 482.1c0 .2 0 .5-.1.6-1.4 3.3-2.8 6.6-4.3 9.9-.2.4-.7.6-1.2.5-2.4-1-4.7-2-7-3-.2-.1-.4-.6-.2-.9.6-1.4 1.2-2.8 1.8-4.2 2.6-6.1 2.8-6.6 3.2-6.4 2.6.7 4.9 2 7.3 3 .2.1.3.3.5.5z',
      },
      type: 'expencive-comfort-wright-style',
      number: {
        n: 34,
        x: 461,
        y: 488.3,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm479.5 500.5c-.2 0-.5 0-.6-.1-2.2-.9-4.5-1.9-6.7-2.9-.7-.3-.8-.7-.5-1.4 1.4-3.2 2.7-6.4 4.1-9.6.3-.6.8-.8 1.4-.5 2.1.9 4.3 1.8 6.4 2.7.8.3.9.7.6 1.3-1.4 3.3-2.8 6.6-4.3 9.9-.1.3-.3.4-.4.6z',
      },
      type: 'expencive-comfort-wright-style',
      isSold: true,
      number: {
        n: 35,
        x: 477.9,
        y: 495.7,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm501.5 496.8c0 .3 0 .6-.1.8-.6 1.4-1.2 2.8-1.8 4.2s-1.1 2.8-1.8 4.2c-1.1 2.3-.8 2.5-3.6 1.3-1.4-.6-2.8-1.2-4.1-1.8-.3-.1-.7-.2-.9-.4s-.4-.6-.5-.9c-.1-.2 0-.5.1-.7 1.4-3.2 2.8-6.4 4.1-9.6.2-.5.7-.7 1.1-.6 2.2 1 4.5 1.9 6.7 2.9.5 0 .7.4.8.6z',
      },
      type: 'expencive-comfort-wright-style',
      isSold: true,
      number: {
        n: 36,
        x: 495,
        y: 503.7,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm518.7 504.3c-.1.2-.1.4-.2.7-1.3 3.1-2.6 6.2-4 9.3-.4.9-.7 1.1-1.6.8-.8-.3-1.5-.5-2.3-.9-1.6-.9-3.6-1-4.9-2.5.3-1.7 1.3-3.2 1.9-4.8.7-1.7 1.4-3.4 2.2-5.1.5-1.1.8-1.3 1.8-.9 2.1.9 4.2 1.9 6.4 2.8.4 0 .7.2.7.6z',
      },
      type: 'expencive-comfort-wright-style',
      isSold: true,
      number: {
        n: 37,
        x: 512.6,
        y: 510.7,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm535.7 511.3c0 .1.1.2.1.3s-.1.2-.1.3c-1.4 3.3-2.8 6.6-4.3 10-.3.6-.8.7-1.4.5-.1 0-.2-.1-.3-.2-.9-.4-1.8-.6-2.6-1.1-1.4-.8-3.2-.8-4.2-2.3.3-1.5 1.1-2.8 1.6-4.2.7-1.8 1.5-3.6 2.3-5.5.1-.2.2-.4.3-.6.3-.6.9-.9 1.4-.7 2.2 1 4.5 1.9 6.7 2.9.2.2.3.4.5.6z',
      },
      type: 'expencive-comfort-wright-style',
      isSold: true,
      number: {
        n: 38,
        x: 529.,
        y: 517.7,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm542.8 524.9c.8-2.9 1.7-5.8 2.5-8.7.1-.3.3-.6.4-1 .2-.5.6-.7 1.1-.6 2.3.7 4.7 1.3 7 2 .3.1.7.8.6 1.1 0 .2-.1.5-.1.7-.9 3.2-1.9 6.5-2.9 9.7-.2.6-.7.9-1.4.7-2.1-.6-4.2-1.3-6.3-1.9-1.3-.4-1.3-.6-.9-2z',
      },
      type: 'expencive-comfort-wright-style',
      isSold: true,
      number: {
        n: 39,
        x: 548.5,
        y: 524.7,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm569.5 534.6c-.6-.2-1.1-.4-1.7-.6-1.9-.6-3.8-1.1-5.7-1.7-.5-.1-.8-.7-.7-1.1.9-3.2 1.9-6.5 2.9-9.7.1-.3.3-.7.4-.9.2-.3.4-.5.8-.4 2.3.7 4.7 1.3 7.1 2 .5.1.9.6.7 1.4-1 3.5-2 6.9-3.1 10.4.1.2-.4.3-.7.6z',
      },
      type: 'expencive-business-wright-style',
      number: {
        n: 40,
        x: 567.5,
        y: 530,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm590.8 528c-1.1 3.8-2 7-3 10.3-.1.2-.2.4-.3.6-.2.4-.7.7-1.1.6-2.3-.7-4.7-1.4-7-2.1-.4-.1-.7-.6-.5-1.1.5-1.7.9-3.4 1.4-5.1 1.4-5 1.7-5.8 2.1-5.8 2-.1 3.8.9 5.7 1.4.7.2 1.3.4 2 .6.5.1.7.6.7.6z',
      },
      type: 'expencive-comfort-wright-style',
      isSold: true,
      number: {
        n: 41,
        x: 584.5,
        y: 535,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm603.9 544.6c-.5-.1-1-.1-1.4-.2-1.9-.5-3.8-1.1-5.7-1.7-.9-.3-1.2-.8-.9-1.5 1-3.3 2-6.7 3-10 .2-.6.8-.9 1.4-.8 2.2.7 4.4 1.3 6.6 2 .7.2 1 .7.9 1.3-1 3.5-2 6.9-3 10.3-.2.3-.7.4-.9.6z',
      },
      type: 'expencive-comfort-wright-style',
      number: {
        n: 42,
        x: 601.5,
        y: 540,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm616.6 535.4c.3.1.6.1.8.1 2.1.6 4.2 1.3 6.4 1.8.9.2 1.2.8.9 1.8-.2.7-.4 1.3-.6 2-.4 1.5-.8 2.9-1.3 4.4-.5 1.4-.5 3-1.6 4.2-1.3-.4-2.7-.7-4-1.1-1.2-.3-2.5-.7-3.7-1.1-.3-.1-.7-.8-.6-1.1 1-3.5 2.1-6.9 3.1-10.4.1-.2.4-.4.6-.6z',
      },
      type: 'business-wright-style',
      isSold: true,
      number: {
        n: 43,
        x: 619,
        y: 545,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm637.4 541.6c.4 0 .6-.1.8 0 2.2.7 4.4 1.3 6.6 2 .6.2.9.7.7 1.3-1 3.3-1.9 6.7-2.9 10-.2.6-.7.9-1.4.7-2.4-.6-4.7-1.2-7-2.1-.5-.2-.7-.6-.6-1.1 1-3.4 2-6.9 3-10.3.3-.1.6-.3.8-.5z',
      },
      type: 'business-wright-style',
      number: {
        n: 44,
        x: 639.5,
        y: 551.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm650.8 557.5c.9-3.2 1.9-6.5 2.8-9.7.1-.3.2-.7.4-.9.1-.2.4-.3.6-.3 2.6.6 5.2 1.3 7.6 2.2.2.1.3.4.3.5 0 .3 0 .7-.1 1-.9 3.1-1.8 6.2-2.8 9.3-.3 1-.6 1.2-1.8.9s-2.5-.7-3.7-1c-.9-.3-1.8-.5-2.7-.8-.5-.1-.8-.7-.6-1.2z',
      },
      type: 'business-wright-style',
      isSold: true,
      number: {
        n: 45,
        x: 656.5,
        y: 556.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm680 554.1c-1.1 4-2.1 7.2-3 10.5-.1.2-.2.4-.3.6-.2.5-.6.7-1.1.6-2.3-.7-4.7-1.3-7-2-.3-.1-.7-.8-.6-1.1s.1-.7.2-1c.9-3 1.8-6 2.7-9 .3-1 .8-1.3 1.8-.9 2.1.7 4.2 1.3 6.3 1.9.6.1 1 .6 1 .4z',
      },
      type: 'business-wright-style',
      isSold: true,
      number: {
        n: 46,
        x: 674,
        y: 561.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm689.7 556.7c2.3.7 4.3 1.2 6.3 1.8.8.2 1.1.8.9 1.6-1 3.4-2 6.7-2.9 10.1-.2.6-.7.8-1.4.6-2.2-.7-4.5-1.3-6.7-2-.4-.1-.9-.7-.8-1 1.1-3.6 2.1-7.1 3.2-10.7.2-.5.6-.7 1.1-.6.1.1.2.2.3.2s.2.1 0 0z',
      },
      type: 'business-wright-style',
      number: {
        n: 47,
        x: 691,
        y: 567,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm706.4 575.2c-2.1-.6-4.2-1.3-6.3-1.9-1.1-.4-1.4-.7-1.1-1.7 1-3.4 2-6.7 2.9-10 .1-.4.7-.7 1.1-.6 2.3.7 4.7 1.3 7 2 .5.1.8.7.7 1-1 3.4-1.9 6.7-2.9 10-.1.2-.2.4-.3.6-.1.5-.6.7-1.1.6z',
      },
      type: 'business-wright-style',
      number: {
        n: 48,
        x: 705,
        y: 571,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm716.4 565c.2 0 .4-.1.5-.1 2.5.7 4.9 1.5 7.3 2.2.4.1.6.8.5 1.2-.9 3.1-1.8 6.3-2.7 9.4-.1.3-.3.6-.4 1-.2.5-.6.8-1.1.6-2.3-.7-4.7-1.4-7-2-.4-.1-.7-.7-.7-1.1 0-.2.1-.5.1-.7 1-3.2 1.9-6.5 2.9-9.7.2-.3.5-.6.6-.8z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 49,
        x: 718.5,
        y: 574.8,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm745.8 573.2c-.9 3-1.7 6.1-2.6 9.1-.2.6-.4 1.1-.6 1.6-.1.2-.6.4-.9.3-2.3-.7-4.7-1.4-7-2.1-.4-.1-.7-.7-.7-1.1.1-.3.1-.7.2-1 .9-3 1.8-6 2.7-9.1.3-1.1.5-1.2 1.8-.9 2.1.6 4.3 1.2 6.4 1.8.6.3.9.9.7 1.4z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 50,
        x: 740,
        y: 579,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm758.7 566.9c.2.1.5.1.6.2.3.2.5.5.6.8 1.6 2.8 3.3 5.7 4.9 8.5.4.7.2 1.2-.6 1.7-1.9 1.1-3.8 2.2-5.8 3.3-.7.4-1.3.2-1.7-.6-1.7-2.9-3.4-5.9-5.1-8.8-.3-.5-.2-1.3.3-1.5 2.1-1.2 4.3-2.4 6.4-3.6z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 51,
        x: 758,
        y: 576,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm419.4 500.6s0 .2-.1.4c-1.4 3.2-2.8 6.4-4.1 9.6-.3.7-.6.8-1.4.4-.4-.2-.9-.3-1.3-.5-4.7-2-5.9-2.5-5.8-3 .3-2.1 1.5-3.8 2.2-5.8.3-.9.8-1.7 1.1-2.6 1.4-3.3 1.1-3.1 4.1-1.8 1.6.7 3.2 1.4 4.8 2.1.4.2.6.5.5 1.2z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 31,
        x: 413.4,
        y: 506,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm425.1 502.2c.2 0 .5 0 .7.1 2.1.9 4.3 1.8 6.4 2.8.6.3.8.8.6 1.4-1.4 3.3-2.8 6.6-4.3 9.9-.2.4-.7.6-1.2.4-2.2-1-4.5-1.9-6.7-2.9-.4-.2-.6-.8-.5-1.2 1.4-3.3 2.8-6.6 4.2-9.9.2-.3.4-.5.8-.6z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 30,
        x: 426.4,
        y: 512,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm441.1 522.5c-.2 0-.5 0-.7-.1-.4-.2-.8-.4-1.3-.6-5.4-2.3-5.8-2.5-5.8-2.9.3-2.1 1.5-3.9 2.2-5.8.6-1.6 1.4-3.2 2.1-4.8.2-.4.8-.6 1.2-.4 2.2.9 4.5 1.9 6.7 2.9.3.1.6.9.5 1.2-1.5 3.4-2.9 6.8-4.4 10.2 0 .1-.2.2-.3.2.1.1-.1.1-.2.1z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 29,
        x: 439.4,
        y: 518,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm454.7 528.2c-.4 0-.6.1-.8 0-2.2-.9-4.5-1.9-6.7-2.9-.3-.1-.6-.9-.4-1.2.4-1.1.9-2.1 1.3-3.2 1-2.2 1.9-4.5 2.9-6.7.3-.6.7-.7 1.4-.4 2 .9 4.1 1.7 6.1 2.6 1 .4 1.2.9.8 1.9-1.3 3.1-2.7 6.2-4 9.3-.2.2-.4.4-.6.6z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 28,
        x: 452.8,
        y: 524,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm465.1 519.8c.4.1.6 0 .8.1 2.1.9 4.3 1.8 6.4 2.8.4.2.7.7.5 1.2-1.4 3.4-2.9 6.8-4.4 10.2-.1.2-.6.3-.9.4-.1 0-.2-.1-.3-.1-2.1-.9-4.3-1.8-6.4-2.8-.6-.3-.9-.9-.7-1.4 1.4-3.3 2.8-6.6 4.3-9.9.1-.3.5-.4.7-.5z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 27,
        x: 466.5,
        y: 530,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm486.2 529.1c-.1.3-.1.6-.1.8-1.4 3.2-2.8 6.4-4.1 9.6-.3.6-.7.8-1.4.5-.1 0-.2-.1-.3-.2-.4-.2-.9-.3-1.3-.5-5.1-2.2-5.5-2.3-5.4-2.8.3-2 1.4-3.7 2.1-5.5.6-1.6 1.4-3.2 2.1-4.8.3-.7.8-.9 1.3-.7 2.2.9 4.5 1.9 6.7 2.9.2.1.3.5.4.7z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 26,
        x: 480,
        y: 535,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm499.5 534.7c0 .2.1.5 0 .7-1.4 3.3-2.8 6.6-4.2 9.9-.2.4-.8.6-1.2.4-2.2-.9-4.5-1.9-6.7-2.9-.4-.2-.7-.8-.5-1.2 1.5-3.4 2.9-6.8 4.4-10.2.1-.2.7-.4.9-.3 2.4 1 4.7 2 7 3 .1.1.2.4.3.6z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 25,
        x: 493,
        y: 541,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm505.6 536.9c2.2.9 4.4 1.9 6.7 2.9.5.2.8.9.5 1.5-1.4 3.2-2.7 6.4-4.1 9.6-.2.6-.8.8-1.4.6-3.8-1.4-7.1-3-7.1-3.4.4-2.4 1.7-4.5 2.6-6.8.5-1.3 1.1-2.6 1.7-3.8.1-.7.5-.8 1.1-.6z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 24,
        x: 506,
        y: 547,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm519.7 543.5c2 .6 4 1.2 6 1.7 1 .3 1.4.9 1.1 1.8-1 3.3-2 6.7-3 10-.1.4-.8.7-1.1.6-2.3-.7-4.7-1.4-7-2.1-.6-.2-.9-.8-.7-1.4.9-3.1 1.9-6.2 2.8-9.3 0-.1.1-.2.1-.3.5-1.1.8-1.5 1.8-1z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 23,
        x: 521,
        y: 553,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm540 550.9c-.8 2.4-1.7 5.4-2.7 8.4-.1.4-.7.7-1.1.5-2-.6-4-1.2-6-1.9-.6-.2-.8-.7-.6-1.4.9-2.9 1.8-5.8 2.7-8.6.2-.5.6-.8 1.1-.7 2.1.6 4.2 1.3 6.3 2 .4.1.6.6.3 1.7z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 22,
        x: 534.5,
        y: 556.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm554.1 554.1v.3c-.9 2.9-1.8 5.8-2.7 8.7-.2.6-.8 1.1-1.3.9-2.1-.6-4.3-1.1-6.3-2-.3-.1-.6-.3-.5-.8 0-.1.1-.2.1-.3.9-2.9 1.7-5.8 2.6-8.7.1-.4.7-.8 1.1-.7 2.2.6 4.5 1.2 6.6 2.1.2 0 .3.3.4.5z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 21,
        x: 548.5,
        y: 560.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm557.2 565.8c.7-2.9 1.7-5.7 2.5-8.5.5-1.9 1-2.1 2.7-1.6 1.6.5 3.1.9 4.6 1.4.8.2 1.1.8.9 1.6-.8 2.8-1.7 5.6-2.5 8.4-.2.8-.9 1.2-1.6.9-1.7-.5-3.3-1-5-1.6-.5 0-1-.3-1.6-.6z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 20,
        x: 562.5,
        y: 565,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm577.8 572.4c-2-.7-4-1.3-6-2-.6-.2-.8-.6-.7-1 0-.1 0-.2.1-.3.8-2.6 1.6-5.1 2.4-7.7.6-1.9.8-1.9 2.7-1.3 1.1.3 2.2.8 3.3 1 2.5.6 2.3 1.1 1.7 3.1-.7 2.2-1.3 4.5-2 6.7-.1.2-.2.4-.3.6-.1.7-.6 1.1-1.2.9z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 19,
        x: 576,
        y: 569,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm589.3 575.8c-1-.3-2.1-.7-3.2-1-1-.3-1.2-.8-.9-1.8s.6-2 .9-3c.6-1.8 1.1-3.5 1.7-5.3.2-.5.6-.8 1.1-.6 1.2.3 2.5.5 3.7 1 3.4 1.2 3.7.4 2.4 4.5-.7 2.1-1.3 4.2-2 6.3-.3.9-.5 1-1.6.7-.6-.4-1.3-.6-2.1-.8z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 18,
        x: 590,
        y: 573,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm605.2 580.2c-1.5-.4-3.5-1-5.5-1.6-.3-.1-.7-.8-.6-1.1.1-.5.2-.9.3-1.4.7-2.2 1.3-4.4 2-6.6.1-.4.3-.9.6-1.3.2-.3.4-.6.9-.4 2.1.7 4.2 1.3 6.3 2 .5.1.7.7.6 1.1-.9 2.9-1.8 5.8-2.7 8.6-.3.7-.7.9-1.9.7z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 17,
        x: 604.5,
        y: 576.4,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm619.8 584.5c-.2 0-.5 0-.7 0-1.9-.5-3.8-1.1-5.6-1.7-.6-.2-1-.8-.8-1.3.9-3 1.8-6 2.8-9 .1-.4.7-.7 1.1-.6 1.7.5 3.3 1 5 1.5 1.8.6 2 .9 1.4 2.8-.6 2.1-1.3 4.2-1.9 6.3-.2.6-.4 1.1-.7 1.6-.2.2-.5.2-.6.4z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 16,
        x: 618,
        y: 581,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm630.1 576.2c.1 0 .2-.1.3-.1 2.4.7 4.9 1.5 7.3 2.2.4.1.8.7.7 1.1-1 3.3-2 6.7-3.1 10-.1.2-.2.4-.3.6-.1.3-.6.5-.9.4-2.2-.7-4.4-1.3-6.7-2-.1 0-.2-.1-.3-.1-.6-.2-.9-.7-.8-1.3 1-3.5 2.1-6.9 3.1-10.4.3-.1.5-.2.7-.4z',
      },
      type: 'business-wright-style',
      isSold: true,
      number: {
        n: 15,
        x: 632,
        y: 586,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm652.1 583c0 .3 0 .7-.1 1-1 3.2-2 6.5-2.9 9.7-.1.4-.7.7-1.1.6-.5-.1-.9-.2-1.4-.3-1.3-.4-2.7-.8-4-1.2-2.9-1-2.8-.6-1.9-3.5.8-2.4 1.5-4.9 2.2-7.4.1-.4.3-.9.6-1.3.2-.3.5-.5.9-.4 2.4.7 4.9 1.5 7.3 2.2.1.1.2.4.4.6z',
      },
      type: 'business-wright-style',
      isSold: true,
      number: {
        n: 14,
        x: 646,
        y: 590,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm662.2 598.4c-.1 0-.4 0-.6 0-1.2-.4-2.4-.8-3.6-1.2-.9-.3-1.8-.6-2.7-.8-1-.2-1.3-.9-1-1.8s.6-1.8.8-2.7c.6-1.9 1.1-3.8 1.7-5.7.1-.3.2-.7.3-1 .3-.8.8-1.1 1.6-.9 1.7.5 3.3 1 5 1.5.7.2 1.3.4 2 .7.3.1.5.5.4.9-.2.7-.3 1.4-.5 2-.8 2.8-1.7 5.6-2.6 8.4-.1.3-.3.6-.8.6z',
      },
      type: 'business-wright-style',
      isSold: true,
      number: {
        n: 13,
        x: 660,
        y: 594,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm680.1 591.4c0 .3-.1.7-.2 1-.9 3.1-1.9 6.2-2.9 9.3 0 .1-.1.2-.1.3-.1.3-.8.7-1.2.5-2.3-.7-4.7-1.4-7-2.1-.6-.2-.8-.7-.6-1.4s.4-1.3.6-2c.7-2.5 1.5-4.9 2.2-7.4.1-.3.3-.6.5-.9.1-.2.4-.3.6-.3.9 0 6.6 1.8 7.7 2.3.2.2.3.5.4.7z',
      },
      type: 'business-wright-style',
      number: {
        n: 12,
        x: 674,
        y: 598,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm693.8 596.7c-.9 2.9-1.7 5.5-2.5 8-.1.4-.3.9-.4 1.3-.2.6-.8 1-1.3.8-1.9-.6-3.8-1.3-5.7-1.8-2.1-.6-2.1-.8-1.6-2.6.9-2.9 1.8-5.8 2.7-8.7.3-1 .8-1.2 1.9-.9 2 .6 4 1.2 6 1.8 1.2.5 1.3.7.9 2.1-.1.1-.1.2 0 0z',
      },
      type: 'premium-wright-style',
      number: {
        n: 11,
        x: 688,
        y: 602,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm708.1 600.3c-1 3.3-2 6.5-3 9.7-.3 1-.8 1.2-1.9.9-2.1-.6-4.2-1.3-6.3-2-.6-.2-.9-.7-.7-1.4.9-3 1.8-6 2.7-9 .1-.4.3-.9.5-1.3.1-.2.4-.3.6-.4h.3c2.5.7 5 1.3 7.3 2.3.3.1.6.4.5.8z',
      },
      type: 'premium-wright-style',
      number: {
        n: 10,
        x: 702,
        y: 607,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm721.3 608.4c-.7 2.2-1.3 4.2-1.9 6.2-.2.6-.8.8-1.4.6-.1 0-.2-.1-.3-.1-5.7-1.7-7.2-2.2-7.1-2.6.3-2.4 1.3-4.7 2-7 .3-1.2.8-2.4 1.2-3.7.2-.6.6-.8 1.4-.7.5.1.9.2 1.4.3 1.1.3 2.2.7 3.3 1.1 3.7 1.1 3 .8 2.1 4-.4.8-.6 1.4-.7 1.9z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 9,
        x: 717,
        y: 611,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm734.7 614.9c0 .2.1.3 0 .4-1.1 3.4-2.1 6.9-3.2 10.3 0 .1-.1.2-.2.3-.2.3-.4.5-.9.4-.6-.2-1.1-.3-1.7-.4-4.7-1.4-6.1-1.9-6.1-2.3 0-2.1 1.1-4 1.6-6 .4-1.6.9-3.1 1.4-4.7.3-.8.7-1 1.6-.7 2.2.7 4.4 1.3 6.6 2 .4.1.7.5.9.7z',
      },
      type: 'premium-wright-style',
      number: {
        n: 8,
        x: 729,
        y: 621,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm749.7 621.8c-.1 1.7-.2 3.5-.3 5.2 0 .6-.6 1.1-1.1 1.1-2.3-.1-4.7-.2-7-.4-.8-.1-1.3-.7-1.2-1.4.2-2.3.3-4.7.5-7 .1-1.2.3-2.3.5-3.5.1-.4.4-.7.7-.7 2.6.1 5.1 0 7.7.6.5.1.8.5.7 1-.2 1.8-.3 3.4-.5 5.1z',
      },
      type: 'premium-wright-style',
      isSold: true,
      number: {
        n: 7,
        x: 745,
        y: 624,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm766.4 624.5c-.1.3-.2.7-.6.8s-.9.4-1.3.5c-1.8.4-3.7.7-5.5 1-1.4.2-1.5.2-1.7-.9-.7-3.2-1.4-6.4-2-9.6 0-.2 0-.5 0-.7 0-.4.2-.8.5-.8 2.5-.6 5-1.2 7.5-1.6.5-.1 1 .2 1.1.7.7 3.3 1.4 6.6 2.1 9.9-.1.2-.1.5-.1.7z',
      },
      type: 'premium-wright-style',
      number: {
        n: 6,
        x: 761,
        y: 622,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm777.1 608.5c.6-.2 1.1 0 1.2.5 1.2 3.4 2.3 6.8 3.5 10.2 0 .1.1.2 0 .3-.1.2-.1.5-.3.6-.2.2-.4.2-.6.3-2.3.8-4.6 1.6-6.9 2.4-.3.1-.7-.1-.8-.5-.2-.4-.4-.8-.5-1.3-.6-1.6-1.1-3.3-1.7-5-.5-1.5-1-3.1-1.5-4.6 1-1.2 2.5-1 3.7-1.7.3-.2.7-.1 1-.3 1.1-.2 2.1-.5 2.9-.9z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 5,
        x: 776,
        y: 618,
        angle: 1,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm787.2 594.3c.3.1.6.2.7.3.2.1.3.4.3.6 1.3 3.2 2.7 6.4 4 9.7.2.4-.1 1.1-.4 1.2-2.4 1-4.7 1.9-7.1 2.9-.3.1-.8-.1-.9-.3s-.3-.4-.4-.6c-1.2-3-2.5-6-3.7-9-.1-.2-.2-.5-.2-.7 0-.3.1-.8.3-.9 2.6-1.2 5.1-2.2 7.4-3.2z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 4,
        x: 786,
        y: 604,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm805.2 598.7c-.1.2-.3.4-.4.5-.7.4-1.4.8-2.2 1-1.9.5-3.5 2-5.6 1.8-1.2-1.5-1.6-3.4-2.3-5-.8-1.7-1.4-3.4-2.1-5.2-.2-.5 0-1 .4-1.2 2.4-1 4.7-1.9 7.1-2.9.4-.2 1 .1 1.1.5 1.3 3.2 2.6 6.5 3.9 9.7 0 .1 0 .2.1.3z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 3,
        x: 799,
        y: 597.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm811.3 597.5c-.2-.1-.4-.2-.5-.4-.3-.6-.5-1.3-.7-2-.7-2.5-1.4-4.9-2.1-7.4-.1-.4-.2-.9-.3-1.4-.1-.4.4-1 .7-1.1 2.3-.7 4.7-1.3 7-2 .4-.1 1 .2 1.1.6.2.7.4 1.3.6 2 .8 2.8 1.6 5.6 2.4 8.4.1.4-.2 1-.6 1.1-2.5.7-4.9 1.4-7.4 2.1.1.1 0 .1-.2.1z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 2,
        x: 813.75,
        y: 593,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm824.7 589c0-1.4-.1-2.8-.1-4.2 0-1 .4-1.5 1.4-1.5.8-.1 1.6-.2 2.4-.1 1.7.1 3.4-.5 5.1.3.6 1.6.2 3.2.4 4.8.1 1.2.2 2.3.3 3.5.1.9.1 1.9.2 2.8 0 .6-.5 1.1-1.1 1.1-.3 0-.7 0-1 .1-1.4.1-2.8.1-4.2.2-3.1.2-3 .2-3.2-3 0-1.5-.1-2.8-.2-4z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 1,
        x: 829,
        y: 592,
        angle: 1,
      },
    },
  ],
}

