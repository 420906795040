export default {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '481,306,446,388,481,403,490,413,551,436,568,442,674,472,685,464,690,480,709,485,718,475,648,349,629,355'
    }
  },
  name: 'Очередь 3',
  id: 3,
  imgForModal: {
    img: '/assets/area3.svg',
    viewBox: {
      x: '432',
      y: '296',
      width: '304.6',
      height: '197.5',
    },
  },
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm500.9 320.4c0 .2 0 .5-.1.7-1.1 3.2-2.2 6.4-3.3 9.6 0 .1-.1.2-.1.3-.2.4-.7.7-1.1.6-1.8-.6-3.5-1.1-5.3-1.7-.4-.1-.9-.3-1.3-.5-.8-.3-1-.7-.8-1.6 0-.1 0-.2.1-.3 1.1-3.1 2.1-6.2 3.2-9.3.1-.2.2-.4.3-.6.1-.1.4-.3.6-.3 2.6.6 5.1 1.6 7.6 2.6.1 0 .1.3.2.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm520.6 328.2c-.5 1.4-1.1 3.4-1.8 5.4-.5 1.4-1 2.8-1.5 4.3-.2.5-.7.7-1.1.6-2.3-.8-4.6-1.6-6.9-2.4-.6-.2-.8-.8-.6-1.4.5-1.5 1-3.1 1.6-4.6.8-1.9 1.1-4 2.2-5.8.2-.4.8-.2 5.2 1.3.3.1.7.2 1 .4.5.2 1.1.4 1.6.7.5.1.6.4.3 1.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm539.4 334.8c-.2.7-.7 2-1.2 3.3-.7 2.1-1.4 4.2-2.2 6.2-.2.4-.7.7-1.1.5-2.3-.8-4.6-1.5-6.9-2.3-.3-.1-.7-.8-.6-1.1.1-.5.2-.9.4-1.3.9-2.5 1.7-5 2.6-7.6.2-.5.4-1.1.7-1.6.2-.3.4-.6.8-.4 2.3.8 4.6 1.5 6.9 2.3.7.3.9.7.6 2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm555.7 339.2v.3c-1.2 3.4-2.3 6.8-3.5 10.3-.2.4-.7.8-1.1.6-2.3-.8-4.6-1.6-6.9-2.4-.1 0-.2-.1-.3-.2-.2-.2-.6-.4-.5-.8 1.2-3.4 2.3-6.9 3.5-10.3.3-.8.7-1 1.6-.7 1.9.6 3.8 1.2 5.6 1.9.4.2.9.4 1.3.6.1.2.2.5.3.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm571.8 344.5v.8c-.5 1.5-1 3.1-1.6 4.6-.5 1.4-1 2.9-1.5 4.3-.8 2.2-.6 2-3 1.3-1.4-.5-2.9-.9-4.3-1.4-.5-.2-1.1-.4-1.6-.7-.2-.1-.3-.3-.3-.5s.1-.4.2-.7c1.1-3.2 2.1-6.4 3.2-9.6.3-.9.9-1.2 1.9-.8 2.1.7 4.2 1.5 6.2 2.2.4 0 .6.3.8.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm577.2 357.7c.9-2.8 2.1-6.1 3.2-9.4.3-.8.8-1 1.6-.8 2.2.7 4.4 1.5 6.6 2.2.6.2.9.7.6 1.4-1.1 3.3-2.3 6.6-3.4 9.9-.2.6-.8.9-1.4.7-2.3-.8-4.6-1.5-6.9-2.3-.4-.1-.7-.7-.3-1.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm599.9 354c.2 0 .5-.1.7 0 2.3.8 4.6 1.5 6.9 2.3.4.1.6.8.5 1.2-1.1 3.3-2.2 6.6-3.3 9.9 0 .1-.1.2-.2.3-.2.4-.8.7-1.2.5-2.4-.8-4.8-1.6-7.2-2.4-.2-.1-.4-.7-.4-.9.8-2.3 1.6-4.6 2.3-6.9.4-1.2.8-2.4 1.3-3.6.2-.3.5-.3.6-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm618.3 360.1c.5.1 1 .1 1.4.3 1.5.5 3.1 1 4.6 1.6 2.5.9 2.3.6 1.5 3.1-1 2.9-1.9 5.7-2.9 8.6-.2.6-.8.9-1.4.7-2.3-.8-4.6-1.5-6.9-2.3-.4-.1-.6-.7-.5-1.2 1.2-3.4 2.3-6.8 3.5-10.2.1-.2.5-.4.7-.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm637.8 376.6c-.3-.1-.5-.1-.7-.3s-.5-.5-.6-.8c-1.1-1.9-2.2-3.8-3.3-5.8-.3-.5-.2-.8.3-1.2.1-.1.2-.1.3-.2 2.9-1.7 5.8-3.4 8.8-5 .9-.5 1.4-.3 2 .6 1 1.7 2 3.4 3 5.1.8 1.4.7 1.6-.7 2.4-2.7 1.6-5.4 3.1-8.2 4.7-.3.3-.6.4-.9.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm658.8 388.9c0 .1 0 .3-.1.3-.1.2-.3.4-.5.5-3.1 1.8-6.3 3.6-9.4 5.4-.3.2-.7.3-.9-.1-1.4-2.3-2.7-4.6-4-7-.1-.2.1-.7.4-.9 3.2-1.9 6.5-3.7 9.7-5.6.3-.2.7 0 .9.3 1.3 2.2 2.6 4.4 3.9 6.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm475.9 348.5c2.5 1 4.6 1.9 6.7 2.8.8.4 1.1.9.8 1.6-1.4 3.2-2.7 6.4-4.1 9.6-.3.6-.8.8-1.4.6-.8-.3-1.5-.6-2.3-.9-4.2-1.8-4.9-2.1-4.8-2.5.3-1.7 1.2-3.2 1.9-4.8.8-1.9 1.6-3.9 2.5-5.8 0-.4.6-.6.7-.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm493.1 369.5c-.4-.1-.7-.1-1.1-.3-1.1-.4-2.2-.9-3.2-1.4-4.3-2-4.3-.9-2.2-5.6 1-2.3 2-4.7 3-7 .2-.5.7-.6 1.2-.4 2.2 1 4.5 1.9 6.7 2.9.4.2.7.8.5 1.2-1.4 3.3-2.8 6.6-4.2 9.9-.1.3-.5.5-.7.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm512.8 364.7c-.1.3 0 .6-.1.8-1.4 3.2-2.7 6.4-4.1 9.6-.2.6-.9.8-1.4.6-1.9-.8-3.8-1.7-5.7-2.5-1.5-.7-1.7-1-1.1-2.3 1.3-3.1 2.7-6.2 4-9.3.2-.4.7-.7 1.1-.5 2.2 1 4.5 1.9 6.7 2.9.2.2.4.5.6.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm527.5 371c0 .2.1.5 0 .7-1.4 3.3-2.8 6.6-4.2 9.9-.2.4-.8.6-1.2.5-2.2-.9-4.5-1.9-6.7-2.9-.3-.1-.7-.8-.5-1.2 1.4-3.4 2.9-6.8 4.4-10.2.1-.2.7-.4.9-.3 2.4 1 4.7 2 7 3 .1 0 .2.3.3.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm535.8 374.3c.3.1.6 0 .8.1 2 .9 4 1.8 6.1 2.6.9.4 1.2.8.7 1.9-1.3 3-2.6 6-3.9 9-.1.3-.3.6-.6.9-.1.1-.5.3-.6.2-2.4-1-4.7-2-7.1-3-.4-.1-.6-.8-.4-1.1 1.4-3.3 2.8-6.6 4.3-9.9.1-.3.5-.5.7-.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm547 391c1-3.5 2.1-6.9 3.1-10.4.1-.4.7-.7 1.1-.6 2.3.7 4.7 1.4 7 2.1.5.1.8.7.7 1.1-1 3.3-2 6.7-3 10-.1.2-.2.4-.3.6-.2.3-.4.6-.8.4-2.3-.7-4.7-1.3-7-2-.6 0-.9-.6-.8-1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm574.6 387.7c-.2.6-.4 1.3-.6 1.9-.8 2.7-1.6 5.4-2.3 8.1-.1.2-.2.4-.3.6-.2.4-.7.7-1.1.6-2.3-.7-4.7-1.4-7-2.1-.4-.1-.7-.6-.6-1.1.1-.3.1-.7.2-1 .9-3 1.8-6 2.7-9 .2-.6.7-1 1.3-.8 1.6.4 3.1.9 4.7 1.4.9.3 1.8.5 2.7.9.1-.1.2.2.3.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm585.3 403.3c-.2 0-.5 0-.7-.1-2.2-.7-4.5-1.3-6.7-2-.5-.2-.7-.6-.6-1.1.2-.8.4-1.6.6-2.4.8-2.6 1.5-5.2 2.3-7.7.2-.7.7-1.1 1.3-.9 2.5.7 4.9 1.5 7.3 2.2.3.1.5.6.4.9-1 3.4-2 6.7-3 10.1-.1.5-.3.8-.9 1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm604.8 397.7c-.1.2-.3 1-.6 1.8-.8 2.6-1.5 5.1-2.3 7.7-.2.6-.8 1-1.3.9-2.3-.7-4.7-1.4-7-2.1-.4-.1-.8-.7-.7-1.1 1-3.4 2-6.9 3.1-10.3.2-.6.7-.8 1.4-.6 2.2.6 4.5 1.3 6.7 1.9.7.1.9.6.7 1.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm608.5 409.3c.9-3.2 1.9-6.5 2.8-9.7.1-.2.2-.4.3-.6.2-.4.7-.7 1.1-.6 2.3.7 4.7 1.4 7 2.1.5.1.7.6.6 1.1 0 .2 0 .5-.1.7-1 3.2-1.9 6.4-2.9 9.7-.1.4-.7.8-1.1.7-1.3-.3-2.7-.7-4-1.1-1-.3-2-.6-3-1-.6-.3-.9-.8-.7-1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm637.8 406.4c0 .2 0 .3-.1.4-.9 3.2-1.9 6.5-2.9 9.7-.1.2-.2.4-.3.6-.2.5-.7.8-1.1.6-2.3-.7-4.7-1.4-7-2.1-.4-.1-.7-.7-.7-1.1 0-.1 0-.2.1-.3 1-3.3 2-6.7 3-10 .1-.4.7-.9 1-.8 2.4.7 4.9 1.5 7.3 2.2.4.2.6.6.7.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm667.3 403.9c-.1.2-.1.5-.3.6-.6.4-1.2.8-1.8 1.1-2.6 1.5-5.3 3-7.9 4.5-.3.2-.8.2-1-.1-1.4-2.3-2.7-4.6-4-6.9-.1-.1.1-.4.2-.6 0-.1.1-.2.2-.3 3.2-1.8 6.5-3.7 9.7-5.5.4-.2.6.1.9.3.2.1.3.4.4.6 1.1 1.9 2.2 3.8 3.3 5.8.2 0 .2.3.3.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm671.8 412.3c.2.1.4.1.6.3.2.1.3.3.4.5 1.2 2 2.3 4 3.5 6 .2.4.1.9-.4 1.2-3 1.7-6 3.5-9 5.2-.8.4-1.4.3-1.8-.4-1.2-2-2.3-4-3.5-6-.4-.7-.3-1 .4-1.4 3-1.8 6-3.5 9-5.2.3-.1.6-.1.8-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm478.4 380.6c1.6.7 3.8 1.6 5.9 2.5 1 .4 1.1.8.7 1.9-1.3 3.1-2.6 6.2-4 9.3-.2.5-.9.8-1.5.5-.2-.1-.5-.1-.7-.2-2.1-1.2-4.6-1.5-6.5-3.2.5-2.3 1.7-4.3 2.6-6.5.6-1.4 1.2-2.8 1.8-4.2.3-.4.7-.5 1.7-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm502.9 391.4c0 .3.1.5 0 .7-1.4 3.2-2.7 6.4-4.1 9.6-.3.6-.9.9-1.4.7-2.2-.9-4.5-1.9-6.7-2.9-.4-.2-.6-.8-.5-1.2.5-1.3 1-2.6 1.6-3.9.9-2.1 1.8-4.3 2.8-6.4.1-.2.7-.4.9-.3 2.4 1 4.7 2 7 3.1.2.2.3.5.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm520.4 399v.4c-1.5 3.4-2.9 6.8-4.4 10.2-.1.2-.6.5-.8.4-2.4-1-4.7-2-7.1-3-.4-.2-.6-.8-.4-1.2 1.4-3.3 2.8-6.6 4.3-9.9.2-.4.7-.7 1.2-.5 2.4 1 4.7 2 7 3 .1.1.1.4.2.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm537.4 406.1c0 .3.1.6 0 .8-1.4 3.3-2.8 6.6-4.2 9.9-.1.3-.8.5-1.2.4-2.2-.9-4.5-1.9-6.7-2.8-.4-.2-.6-.8-.5-1.2 0-.1 0-.2.1-.3 1.4-3.2 2.8-6.4 4.1-9.6.3-.7.7-.8 1.4-.5 2.1.9 4.3 1.8 6.4 2.7.2.1.4.4.6.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm549.3 422.7c-.2 0-.4 0-.5 0-2.4-.7-4.9-1.5-7.3-2.2-.2-.1-.5-.6-.4-.9 1-3.6 2.1-7.1 3.2-10.7.1-.3.4-.6.8-.6.1 0 .2 0 .3.1 2.2.6 4.5 1.3 6.7 1.9.6.2 1 .8.8 1.3-.4 1.6-.9 3.1-1.3 4.7-.5 1.9-1.1 3.8-1.7 5.7-.1.4-.4.6-.6.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm562.7 413.5c1.8.5 4 1.2 6.3 1.8.6.2 1 .8.9 1.3-1 3.6-2.1 7.1-3.1 10.7-.1.2-.6.4-.9.3-1-.2-2.1-.4-3-.9-1.6-.7-3.4-.5-4.8-1.8.1-1.6.8-3.1 1.2-4.7.6-2.1 1.3-4.2 1.9-6.3.2-.4.5-.6 1.5-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm589.8 422.8c-1.1 3.7-2 6.7-2.9 9.7-.2.6-.7 1-1.3.9-2.4-.6-4.7-1.2-7-2.1-.4-.2-.6-.6-.5-1.1 0-.2.1-.5.1-.7.9-3.1 1.9-6.3 2.8-9.4.2-.6.7-.9 1.3-.8 2.4.6 4.7 1.2 7 2.1.5.2.7.6.6 1.1-.1.3-.2.6-.1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm609.7 427.8c0 .1.1.2 0 .3-1 3.5-2 6.9-3 10.4-.2.6-.8.9-1.4.7-2.2-.6-4.5-1.3-6.7-1.9-.6-.2-1-.7-.8-1.3 1-3.5 2-6.9 3-10.4.1-.3.8-.7 1.2-.5 2.4.7 4.9 1.5 7.3 2.2.1 0 .2.3.4.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm615.1 441.1c1-3.2 1.9-6.5 2.9-9.7.4-1.4.5-1.5 1.9-1.1.1 0 .2.1.3.1 1.9.5 3.8 1.1 5.7 1.6 1 .3 1.2.7.9 1.8-.9 3-1.7 6-2.6 9.1-.5 1.8-.7 1.6-2.2 1.2-2-.6-4-1.1-6-1.7-.7-.2-1-.8-.9-1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm635.7 435h.3c.7.2 1.3.4 2 .6 4.6 1.3 5.8 1.7 5.8 2.1-.1 2-.9 3.8-1.4 5.7-.5 1.8-1.1 3.6-1.6 5.3-.2.5-.6.7-1.1.6-2.3-.7-4.7-1.4-7-2.1-.4-.1-.7-.8-.6-1.1.3-1.2.6-2.5 1-3.7.7-2.3 1.4-4.7 2.2-7-.1-.2.2-.3.4-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm659.6 442.4v.7c-.9 3.3-1.9 6.5-2.8 9.8-.2.8-.8 1.2-1.6 1-2.2-.6-4.5-1.3-6.7-2-.7-.2-.9-.6-.7-1.3 1-3.4 2-6.7 2.9-10.1.1-.4.7-.8 1.1-.7.9.2 1.8.5 2.7.7 1.6.5 3.1.9 4.7 1.5.1-.1.2.2.4.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm671.7 467.6c-2.3-.7-4.7-1.4-7-2.1-.4-.1-.8-.7-.6-1.1 1-3.4 2-6.7 3-10 .1-.2.2-.4.3-.6.2-.3.4-.6.8-.4 2.3.7 4.7 1.3 7 2 .6.2.9.8.7 1.4-1 3.5-2 6.9-3 10.4-.1.3-.8.6-1.2.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm694.1 451c-.1.2-.2.5-.4.6-3.2 1.9-6.5 3.7-9.7 5.5-.1.1-.4 0-.6-.1s-.4-.3-.5-.5c-1.2-2-2.3-4-3.5-6-.3-.6-.2-1.1.4-1.5 3.1-1.8 6.3-3.6 9.4-5.4.4-.2.9-.1 1.2.4 1.2 2.1 2.4 4.2 3.6 6.4.2.1.1.4.1.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm694.4 475.9c-.1-.1-.2-.1-.3-.2-.2-.2-.3-.3-.5-.5-1.2-2-2.3-4-3.5-6.1-.4-.6-.3-.9.5-1.4 1.1-.7 2.2-1.3 3.3-1.9 6.1-3.5 6.5-3.7 6.7-3.4 1.7 2 2.7 4.4 4 6.5.1.1.1.2.2.3.2.3 0 1-.4 1.2-3.1 1.8-6.3 3.6-9.4 5.3-.1.1-.3.1-.6.2z',
      },
    },
  ],
}
